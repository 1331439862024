/* eslint-disable react-hooks/exhaustive-deps */
import "./drones.css";

import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import { PAGE_ROUTES } from "../../utils/stateTypes";
import StandardButton from "../general/StandardButton";
import BackgroundEffect from "../general/BackgroundEffect";
import IndustriesCarousel from "../home/IndustriesCarousel";
import Blip from "./Blip";
import ComponentBlock from "./ComponentBlock";


const INTRO_GAL = [
  {
    img: "/images/drones/elevation-s/gal-1.jpg",
    label: "Aerodynamic elegance",
  },
  {
    img: "/images/drones/elevation-s/gal-2.jpg",
    label: "Function-driven design",
  },
  {
    img: "/images/drones/elevation-s/gal-3.jpg",
    label: "Patented technology",
  },
];

const BOX_ITEMS = [
  {
    head: "Your Elevation-S",
    description: "Fuselage, inner wings & tail, configured payload, and 2x outer wings",
    thumb: "/images/drones/elevation-s/box-thumb-1.png",
    image: "/images/drones/elevation-s/box-image-1.png",
  },
  {
    head: "Transport case",
    description: "Lightweight transport container or backpack case for easy transport and storage",
    thumb: "/images/drones/elevation-s/box-thumb-5.png",
    image: "/images/drones/elevation-s/box-image-5.png",
  },
  {
    head: "Ground control",
    description: "Ground control tablet with Mission Planning Software and ground-based datalink",
    thumb: "/images/drones/elevation-s/box-thumb-4.png",
    image: "/images/drones/elevation-s/box-image-7.png",
  },
  {
    head: "Manual controller",
    description: "RC controller for manual flight control and emergency override",
    thumb: "/images/drones/elevation-s/box-thumb-6.png",
    image: "/images/drones/elevation-s/box-image-9.png",
  },
  {
    head: "Battery",
    description: "Lithium ion battery pack and battery charger unit",
    thumb: "/images/drones/elevation-s/box-thumb-2.png",
    image: "/images/drones/elevation-s/box-image-6.png",
  },
  {
    head: "Extras",
    description: "Anemometer, spare landing struts, spare propellers, operating manual",
    thumb: "/images/drones/elevation-s/box-thumb-3.png",
    image: "/images/drones/elevation-s/box-image-8.png",
  },
];


const ElevationS = () => {

  //Set up intro gallery
  const [galIndex, setGalIndex] = React.useState(0);
  //Auto rotate images
  const [intervalItem, setIntervalItem] = React.useState(null);
  React.useEffect(() => {
    let intervalLength = 5000;
    if (intervalItem) {
      clearInterval(intervalItem);
    }
    else {
      //First or manually set, longer interval
      intervalLength = 10000;
    }
    setIntervalItem(setInterval(() => {
      setGalIndex((galIndex + 1) % INTRO_GAL.length);
    }, intervalLength));
    return () => {
      clearInterval(intervalItem);
    };
  }, [galIndex]);

  //Clear interval when manually changing image
  const manuallyChangeImage = (i) => {
    clearInterval(intervalItem);
    setIntervalItem(null);
    setGalIndex(i);
  };

  //Set up box section
  const [boxView, setBoxView] = React.useState(0);
  const setBoxViewCB = (newBoxView) => (
    () => {
      setBoxView(newBoxView);
    }
  );
  //Preload the box images
  React.useEffect(() => {
    BOX_ITEMS.forEach((boxItem) => {
      const img = new Image();
      img.src = boxItem.image;
    });
  }, []);


  return (
    <main>
      <Helmet>
        <title>Elevation-S | Avior Labs</title>
        <meta
          name={"description"}
          content={"Elevation-S is a long-endurance VTOL drone designed for surveying and monitoring applications. It is fully electric and offers over 90 minutes of useful flight time."}
        ></meta>
      </Helmet>
      <BackgroundEffect />
      
      <header className={"drone-header"}>
        <h1>ELEVATION-<span>S</span></h1>
        
        <div className={"drone-blips-cont standard-width"}>
          <img
            className={"drone-blips-img"}
            src={"/images/drones/elevation-s/elevation-s-blips-1.jpg"}
          />
          <Blip
            head={"Detachable wings"}
            text={"2.54m  wingspan with detachable outer wings for transportability and ease of assembly."}
            x={"15%"}
            y={"30%"}
            right={true}
            top={true}
          />
          <Blip
            head={">90 mins useful flight"}
            text={"Fully electric with 90 minutes useful flight time in its standard configuration."}
            x={"53%"}
            y={"32%"}
            right={false}
            top={true}
          />
          <Blip
            head={"Convenient to transport"}
            text={"Light and packages in a convenient case that fits in the boot of a sedan car."}
            x={"75%"}
            y={"61%"}
            right={false}
            top={false}
          />
          <Blip
            head={"Customisable payload"}
            text={"Can be equipped with any suitable payload up to 750g."}
            x={"39%"}
            y={"50%"}
            right={true}
            top={false}
          />
          <Blip
            head={"Patented configuration - SilentFly™"}
            text={"Single tilting propeller on the nose offers flight efficiency and a low noise profile."}
            x={"27%"}
            y={"43%"}
            right={true}
            top={false}
          />
        </div>
      </header>

      <section className={"drone-intro-section standard-width"}>
        <h2 className={"section-head"}>
          Take flight<br />with Elevation-S
        </h2>
        <div className={"drone-intro-gal"}>
          <div className={"drone-intro-gal-main-cont"}>
            {
              INTRO_GAL.map((gal, i) => (
                <img
                  className={`drone-intro-gal-main standard-box ${galIndex === i ? "active" : ""}`}
                  src={gal.img}
                  key={i}
                />
              ))
            }
          </div>
          <div className={"drone-intro-gal-thumbs"}>
            <h3>{INTRO_GAL[galIndex].label}</h3>
            {
              INTRO_GAL.map((gal, i) => (
                <div
                  className={`drone-intro-gal-thumb-cont ${galIndex === i ? "active" : ""}`}
                  onClick={() => manuallyChangeImage(i)}
                  key={i}
                >
                  <img
                    className={"drone-intro-gal-thumb standard-box"}
                    src={gal.img}
                  />
                </div>
              ))
            }
          </div>
        </div>
      </section>

      <section className={"drone-features-section"}>
        <img
          className={"drone-features-bg"}
          src={"/images/drones/elevation-s/features-bg2.jpg"}
        />
        <div className={"drone-features-bg-color"} />

        <div className={"drone-features-content standard-width"}>
          <p className={"section-pre-head"}>Key features</p>
          <h2 className={"section-head"}>Powerful & adaptable</h2>

          <div className={"drone-features-blocks"}>
            <div className={"drone-features-block standard-box"}>
              <h3>SilentFly™ technology</h3>
              <div className={"divider small"} />
              <p>
                At Avior Labs, we understand the importance of minimising the impact of our drones on the
                environment and the people and animals around them. Elevation-S makes use of a unique and
                patented VTOL configuration, where only one of its four motors is tilted forward for conventional
                flight. This configuration provides a low noise profile that is significantly lower than that of
                other drones in its class. 
              </p>
            </div>
            <div className={"drone-features-block standard-box"}>
              <h3>Adaptable payload</h3>
              <div className={"divider small"} />
              <p>
                With surveying being the primary use case for Elevation-S, the drone is designed to be equipped
                with a variety of fixed, non-gimballed payloads. The maximum payload capacity of 750g allows for
                a wide range of sensors to be used, including high-resolution, multispectral, and thermal
                cameras. Our team will assist you in selecting the right payload for your specific needs.
              </p>
            </div>
            
            <div className={"drone-features-block standard-box"}>
              <h3>Exceptional efficiency</h3>
              <div className={"divider small"} />
              <p>
                Elevation-S makes use of a high aspect ratio wing and tractor propeller which allows us to maximise
                the aerodynamic efficiency and thus decrease the energy required to fly. The forward swept wings are
                a function of our unique configuration and provides for forgiving flight characteristics. The Elevation-S airframes are
                equipped with optimised embedded antenna technology that provides for exceptional control range.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={"drone-payloads-section standard-width"}>
        <h2 className={"section-head"}>Payloads</h2>
        <div className={"divider"} />
        <ComponentBlock
          image={"/images/drones/elevation-s/payloads-1.png"}
          head={"FLIR Vue Pro Thermal Camera"}
          text={"Designed for professional use, FLIR Vue Pro is more than a thermal camera, it is a thermal measurement instrument and data recorder that adds tremendous value to your sUAS operations and services."}
          linkText={"View product (external link)"}
          link={"https://www.flir.com/products/vue-pro"}
          altStyle={true}
        />
        <ComponentBlock
          image={"/images/drones/elevation-s/payloads-5.png"}
          head={"ADTi 26MP 26S V2 Surveyor Camera"}
          text={"The 26S V2 is an ADTI self-developed 26MP APS-C camera module. It adopts the Sony IMX 571 back-illuminated 26MP image sensor, for unparalleled crystal-clear imaging. ADTi also provides a variety of lenses to meet different application requirements."}
          linkText={"View product (external link)"}
          link={"https://docs.adti.camera/adti-26s-v2-module-quick-start-guide"}
          altStyle={false}
        />
        <ComponentBlock
          image={"/images/drones/elevation-s/payloads-7.png"}
          head={"Sony ILX-LR1 61MP Full Frame Camera"}
          text={"The ILX-LR1 is an interchangeable lens professional camera with approximately 61.0 effective megapixels and a 35mm full-frame image sensor. The class-leading ISO & dynamic range performance make it ideal for aerial surveying."}
          linkText={"View product (external link)"}
          link={"https://www.image-sensing-solutions.eu/ILX-LR1.html"}
          altStyle={true}
        />
        <ComponentBlock
          image={"/images/drones/elevation-s/payloads-6.png"}
          head={"MicaSense RedEdge-P MultiSpectral Camera"}
          text={"RedEdge-P features a high-resolution panchromatic band for pan-sharpened output resolutions of 2 cm at 60 m. Its five narrow multispectral bands with scientific-grade filters make it the perfect camera for calculating multiple vegetation indices and composites."}
          linkText={"View product (external link)"}
          link={"https://ageagle.com/drone-sensors/rededge-p-high-res-multispectral-camera/"}
          altStyle={false}
        />
      </section>

      <section className={"drone-specs-section"}>
        <div className={"drone-specs-bg"} />
        <div className={"standard-width"}>
          <h2 className={"section-head"}>Specifications</h2>

          <div className={"drone-specs-tables-cont"}>
            <table className={"drone-specs-table standard-box"}>
              <tbody>
                <tr>
                  <td>Wingspan</td>
                  <td>2.54 m</td>
                </tr>
                <tr>
                  <td>MTOW</td>
                  <td>4.6 kg</td>
                </tr>
                <tr>
                  <td>Endurance</td>
                  <td>90 mins</td>
                </tr>
                <tr>
                  <td>Best endurance airspeed</td>
                  <td>15 m/s</td>
                </tr>
              </tbody>
            </table>
            <table className={"drone-specs-table standard-box"}>
              <tbody>
                <tr>
                  <td>Best range airspeed</td>
                  <td>18 m/s</td>
                </tr>
                <tr>
                  <td>Wind tolerance in hover</td>
                  <td>8 m/s</td>
                </tr>
                <tr>
                  <td>Datalink range</td>
                  <td>15 km</td>
                </tr>
                <tr>
                  <td>Maximum operating altitude</td>
                  <td>6500 ft</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>

      <section className={"drone-industries standard-width"}>
        <IndustriesCarousel
          slides={[
            {
              head: "Mining",
              img: "/images/home/industry1.jpg",
              description: "Survey drones are becoming indispensable in the mining industry due to their ability to provide accurate, real-time data while significantly enhancing safety and efficiency. With Elevation-S one can quickly and safely map large and hazardous areas, capturing high-resolution images and 3D models that are crucial for planning and monitoring mining operations.",
            },
            {
              head: "Energy",
              img: "/images/home/industry2.jpg",
              description: "Elevation-S can quickly assess the condition of solar panels and coal conveyors, providing high-resolution images and thermal data that help identify potential issues such as corrosion or structural damage. By using Elevation-S, energy companies can reduce the need for manual inspections, which are not only time-consuming but also pose significant safety risks to personnel.",
            },
            {
              head: "Civil Structures",
              img: "/images/home/industry3.jpg",
              description: "Elevation-S can be used to survey linear infrastructure such as roads, pipelines, and railway lines. Its ability to provide a considerably longer useful flight compared to conventional multirotor drones enables it to survey over longer distances and in difficult to access  locations.",
            },
            {
              head: "Agriculture",
              img: "/images/home/industry4.jpg",
              description: "Survey drones are highly beneficial for agriculture, offering farmers a powerful tool to enhance crop management and productivity. Elevation-S can quickly cover large fields, capturing high-resolution images and multispectral data that provide insights into crop health, soil conditions, and irrigation needs.",
            },
            {
              head: "Conservation",
              img: "/images/home/industry5.jpg",
              description: "Elevation-S, with its SilentFly™ technology, is ideal for wildlife monitoring and conservation efforts. The drone can capture data that can be further analysed by Avior Labs' Wildlife e-Census to help conservation managers track and study animal populations, monitor habitats, and identify threats to biodiversity.",
            },
          ]}
        />
      </section>

      <section className={"drone-box-section"}>
        <div className={"drone-box-bg-color"} />
        <div className={"standard-width"}>
          <h2 className={"section-head"}>What's in the box?</h2>

          <div className={"drone-box-cont"}>
            <div className={"drone-box-items"}>
              {
                BOX_ITEMS.map((boxItem, i) => {
                  if (i % 2 === 0) {
                    return (
                      <div
                        key={i}
                        className={["db-item-cont", (boxView === i) ? "active" : ""].join(" ")}
                        onClick={setBoxViewCB(i)}
                      >
                        <div className={"db-item-img"}>
                          <img src={boxItem.thumb} />
                        </div>
                        <div className={"db-item-text"}>
                          <h3>{boxItem.head}</h3>
                          <p>{boxItem.description}</p>
                        </div>
                      </div>
                    );
                  }
                })
              }
            </div>
          

            <div className={"drone-box-view"}>
              {
                BOX_ITEMS.map((boxItem, i) => (
                  <img
                    key={i}
                    className={(boxView === i) ? "active" : ""}
                    src={boxItem.image}
                  />
                ))
              }
            </div>

            <div className={"drone-box-items"}>
              {
                BOX_ITEMS.map((boxItem, i) => {
                  if (i % 2 === 1) {
                    return (
                      <div
                        key={i}
                        className={["db-item-cont", (boxView === i) ? "active" : ""].join(" ")}
                        onClick={setBoxViewCB(i)}
                      >
                        <div className={"db-item-img"}>
                          <img src={boxItem.thumb} />
                        </div>
                        <div className={"db-item-text"}>
                          <h3>{boxItem.head}</h3>
                          <p>{boxItem.description}</p>
                        </div>
                      </div>
                    );
                  }
                })
              }
            </div>
          </div>
        </div>
      </section>

      {/* ================================== CTA ================================== */}
      <section className={"drone-cta-section standard-width"}>
        <p className={"section-head-pre"}>Get started</p>
        <h2 className={"section-head"}>Fly longer with Elevation-S</h2>
        <p className={"drone-cta-text"}>
          Get started today with Elevation-S. Whether you're looking to rent, rent to own, or buy, our support team 
          is here to help you get the most out of your drone. Contact us today to learn more or organise a demo.
        </p>
        <Link to={PAGE_ROUTES.CONTACT}>
          <StandardButton
            label={"Rent"}
            className={"drone-cta-button"}
          />
        </Link>
        <Link to={PAGE_ROUTES.CONTACT}>
          <StandardButton
            label={"Rent to own"}
            className={"drone-cta-button"}
          />
        </Link>
        <Link to={PAGE_ROUTES.CONTACT}>
          <StandardButton
            label={"Buy"}
            className={"drone-cta-button"}
          />
        </Link>
        <img
          className={"drone-cta-img standard-box"}
          src={"/images/drones/elevation-s/contact-elevation-s-1.jpg"}
        />
      </section>
    </main>
  );
};

export default ElevationS;