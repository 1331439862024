import "./about.css";

import React from "react";
import { Helmet } from "react-helmet";

import BackgroundEffect from "../general/BackgroundEffect";
import StandardButton from "../general/StandardButton";

const FEATURED_NEWS = {
  type: "Event",
  title: "Avior Labs to be featured at the 2024 AAD Expo",
  body: "We are excited to be featured at the 2024 Africa Aerospace and Defence Expo in Pretoria, South Africa. We will be showcasing the Elevation-G UAV and our Wildlife e-Census solution. Visit the exhibition stand to learn more about our products and solutions - and how we can empower your business!",
  date: "18-20 September 2024",
  image: "/images/about/news/news-1.jpg",
  link: "https://www.aadexpo.co.za/",
  linkLabel: "AAD Website",
};

const NEWS_ITEMS = [
  {
    type: "Press",
    title: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    date: "August 2024",
    image: "/images/about/news/news-2.jpg",
  },
  {
    type: "Blog",
    title: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    date: "June 2024",
    image: "/images/about/news/news-3.jpg",
  },
  {
    type: "Blog",
    title: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    date: "April 2024",
    image: "/images/about/news/news-4.jpg",
  },
  {
    type: "Event",
    title: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    date: "August 2024",
    image: "/images/about/news/news-5.jpg",
  },
  {
    type: "Press",
    title: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    date: "August 2024",
    image: "/images/about/news/news-6.jpg",
  },
  {
    type: "Press",
    title: "Lorem ipsum dolor sit amet consectetur adipiscing elit",
    body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    date: "August 2024",
    image: "/images/about/news/news-7.jpg",
  },
];

const About = () => {

  return (
    <main>
      <Helmet>
        <title>About | Avior Labs</title>
        <meta
          name={"description"}
          content={"Learn more about Avior Labs, our history, and our mission to empower businesses with innovative drone technology."}
        ></meta>
      </Helmet>
      <BackgroundEffect />
      
      <header className={"about-header"}>
        <h1>ABOUT</h1>
        <img
          className={"about-header-img"}
          src={"/images/about/about-header.jpg"}
        />
      </header>

      <section className={"about-history-section standard-width"}>
        <h2 className={"section-head"}>Our history</h2>
        <p className={"about-intro-text"}>
          Avior Labs is an aerospace company that designs, develops, and manufactures unmanned
          aerial vehicles (UAV) and associated solutions for commercial and humanitarian applications.
          The company was founded by two experienced aeronautical engineers with a passion for all
          things flying and an economist with a love for research and analytics.
          <br /><br />
          The idea to start a new
          company was conceived out of the desire to apply decades of combined experience in
          aerospace R&D and consulting to develop and produce our own products and solutions.
          Recognising the enormous opportunities for drone applications in sub-Saharan Africa and the
          potential to apply the founders’ unique set of skills to exploit these opportunities sealed the
          deal. Avior Labs was born.
        </p>

        <div className={"timeline"}>
          <div className={"container left"}>
            <div className={"content"}>
              <h3>January 2019</h3>
              <p>The company is launched by two aeronautical engineers and an economist with a passion for aerospace.</p>
              <img src={"/images/about/timeline/Timeline1_OG.png"} />
            </div>
          </div>
          <div className={"container right"}>
            <div className={"content"}>
              <h3>February 2019</h3>
              <p>
                Work starts on the development of the Avior Labs Universal Simulation Environment (USE) – an extremely powerful, fully
                networked simulation environment developed from first principles, implemented in C++ and providing multi-layered
                fidelity for the modelling of the dynamics of a single or many interacting vehicles or entities.
              </p>
              <img src={"/images/about/timeline/Timeline2_USE.png"} />
            </div>
          </div>
          <div className={"container left"}>
            <div className={"content"}>
              <h3>May 2019</h3>
              <p>Conceptual studies start on the development of a small VTOL UAV for the commercial market.</p>
              <img src={"/images/about/timeline/Timeline3_conceptual_studies.jpg"} />
            </div>
          </div>
          <div className={"container right"}>
            <div className={"content"}>
              <h3>March 2020</h3>
              <p>
                Work on the integration of a software-in-the-loop simulation (SILS) and hardware-in-the-loop
                simulation (HILS) capability in USE matures – we can now run the actual firmware used on
                UAVs or other vehicles in fully interfaced independent threads, or on the actual hardware.
              </p>
              <img src={"/images/about/timeline/Timeline4_SILS.jpg"} />
            </div>
          </div>
          <div className={"container left"}>
            <div className={"content"}>
              <h3>December 2020</h3>
              <p>
                The user requirement specification (URS) for the Elevation VTOL UAV is issued, marking
                the start of its development.
              </p>
              <img src={"/images/about/timeline/Timeline5_Elevation_URS.png"} />
            </div>
          </div>
          <div className={"container right"}>
            <div className={"content"}>
              <h3>October 2021</h3>
              <p>Flight testing on the first of many flying laboratories starts – the Ascension Lift Rig.</p>
              <img src={"/images/about/timeline/Timeline6_Ascension_lift_rig_option2.jpg"} />
            </div>
          </div>
          <div className={"container left"}>
            <div className={"content"}>
              <h3>April 2022</h3>
              <p>First flight testing starts on the SkyEye flying laboratory.</p>
              <img src={"/images/about/timeline/Timeline7_SkyEye.jpg"} />
            </div>
          </div>
          <div className={"container right"}>
            <div className={"content"}>
              <h3>November 2022</h3>
              <p>First flight of the Elevation VTOL prototype.</p>
              <img src={"/images/about/timeline/Timeline8_Elevation_first_flight_option1.jpg"} />
            </div>
          </div>
          <div className={"container left"}>
            <div className={"content"}>
              <h3>June 2023</h3>
              <p>First flight of the production proof-of-concept Elevation airframe.</p>
              <img src={"/images/about/timeline/Timeline9_Elevation_production_option1.jpg"} />
            </div>
          </div>
          <div className={"container right"}>
            <div className={"content"}>
              <h3>August 2023</h3>
              <p>SACAA grants Elevation a Proving Flight Authority. First flight away from our home base.</p>
              <img src={"/images/about/timeline/Timeline10_Elevation_away_from_home.jpg"} />
            </div>
          </div>
          <div className={"container left"}>
            <div className={"content"}>
              <h3>November 2023</h3>
              <p>Initial R&D commences on Callisto – our next generation long endurance VTOL UAV.</p>
              <img src={"/images/about/timeline/Timeline11_Callisto_option2.jpg"} />
            </div>
          </div>
          <div className={"container right"}>
            <div className={"content"}>
              <h3>January 2024</h3>
              <p>Production on a pre-production batch of 4 additional Elevation airframes commences.</p>
              <img src={"/images/about/timeline/Timeline12_production.jpg"} />
            </div>
          </div>
          <div className={"container left"}>
            <div className={"content"}>
              <h3>April 2024</h3>
              <p>Avior Labs makes an agreement with the first Elevation operator to conduct joint operational testing on the airframes.</p>
              <img src={"/images/about/timeline/Timeline12.jpg"} />
            </div>
          </div>
        </div>
      </section>

      <section className={"about-team-header"}>
        <img
          className={"about-team-header-bg"}
          src={"/images/about/team-header-1.jpg"}
        />

        <div className={"standard-width"}>
          <h2 className={"section-head"}>Meet the team</h2>
        </div>
      </section>

      <section className={"about-team-section"}>
        <div className={"standard-width"}>
          <div className={"team-cont"}>
            <div className={"team-member"}>
              <img
                src={"/images/about/team-bennie.jpg"}
                className={"standard-box"}
              />
              <div className={"team-member-text"}>
                <h3>Dr Bennie Broughton</h3>
                <p>Managing Director and Co-Founder</p>
                <p>PhD Aerospace Engineering (Illinois)</p>
              </div>
            </div>
            <div className={"team-member"}>
              <img
                src={"/images/about/team-kreelan.jpg"}
                className={"standard-box"}
              />
              <div className={"team-member-text"}>
                <h3>Kreelan Padayachee</h3>
                <p>Technical Director and Co-Founder</p>
                <p>MSc Aeronautical Engineering (Wits)</p>
              </div>
            </div>
            <div className={"team-member"}>
              <img
                src={"/images/about/team-prinal.jpg"}
                className={"standard-box"}
              />
              <div className={"team-member-text"}>
                <h3>Dr Prinal Naidoo</h3>
                <p>Director: Global Strategy</p>
                <p>PhD Aeronautical Engineering (Wits)</p>
              </div>
            </div>
            <div className={"team-member"}>
              <img
                src={"/images/about/team-lena.jpg"}
                className={"standard-box"}
              />
              <div className={"team-member-text"}>
                <h3>Lena Broughton</h3>
                <p>Financial Director and Co-Founder</p>
                <p>MBA (Henley), MSc Technology Management (UP)</p>
              </div>
            </div>
            <div className={"team-member"}>
              <img
                src={"/images/about/team-crispin.jpg"}
                className={"standard-box"}
              />
              <div className={"team-member-text"}>
                <h3>Crispin McKenzie </h3>
                <p>Aeronautical Engineer</p>
                <p>BSc Aeronautical Engineering (Wits)</p>
              </div>
            </div>
            <div className={"team-member"}>
              <img
                src={"/images/about/team-zelmarie.jpg"}
                className={"standard-box"}
              />
              <div className={"team-member-text"}>
                <h3>Zelmarie Kruger</h3>
                <p>Software Engineer</p>
                <p>BScHons Computer Sciences and Information Technology (NWU)</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={"news-section"}>
        <div className={"standard-width"}>
          <h2 className={"section-head"}>Latest news</h2>
          <div className={"news-item-featured"}>
            <img src={FEATURED_NEWS.image} />
            <div className={"news-item-featured-content"}>
              <p className={"news-type"}>{FEATURED_NEWS.type}</p>
              <h3 className={"news-head"}>{FEATURED_NEWS.title}</h3>
              <p className={"news-body"}>{FEATURED_NEWS.body}</p>
              {
                (FEATURED_NEWS.link) && (
                  <a
                    href={FEATURED_NEWS.link}
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    <StandardButton
                      label={FEATURED_NEWS.linkLabel}
                      className={"news-link"}
                    />
                  </a>
                )
              }
              <p className={"news-date"}>{FEATURED_NEWS.date}</p>
            </div>
          </div>

          {/* <div className={"news-cont"}>
            {
              NEWS_ITEMS.map((news, index) => {
                return (
                  <div
                    key={index}
                    className={"news-item standard-box"}
                  >
                    <img src={news.image} />
                    <div className={"news-item-content"}>
                      <p className={"news-type"}>{news.type}</p>
                      <h3 className={"news-head"}>{news.title}</h3>
                      <p className={"news-body"}>{news.body}</p>
                      <p className={"news-date"}>{news.date}</p>
                    </div>
                  </div>
                );
              })
            }
          </div> */}
        </div>
      </section>
    </main>
  );
};

export default About;