/* eslint-disable react-hooks/exhaustive-deps */
import "./home.css";

import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Tabs, Tab } from "@material-ui/core";

import { PAGE_ROUTES } from "../../utils/stateTypes";
import StandardButton from "../general/StandardButton";
import BackgroundEffect from "../general/BackgroundEffect";
import Specs from "../sections/Specs";
import IndustriesCarousel from "./IndustriesCarousel";

const styles = {
  videoTabs: {
  },
};


const Home = () => {

  const [vidTab, setVidTab] = React.useState(0);

  return (
    <main>
      <Helmet>
        <title>Avior Labs</title>
        <meta
          name={"description"}
          content={"Avior Labs is an aerospace company that designs, develops, and manufactures unmanned aerial vehicles (UAV) and associated solutions for commercial and humanitarian applications."}
        ></meta>
      </Helmet>
      <BackgroundEffect />
      
      {/* ================================== HERO ================================== */}
      <header className={"home-header"}>
        {/* MP4 background */}
        <video
          autoPlay
          muted
          loop
          className={"home-header-bg"}
        >
          <source
            src={"/images/home/hero3-compressed.mp4"}
            type={"video/mp4"}
          />
        </video>
        <div className={"home-header-bg-overlay"} />
        <div className={"home-header-content standard-width"}>
          <h1>Simplify life through flight</h1>
          <div className={"home-header-divider"} />
          <p>Cost-effective solutions for unmanned aerial operations.</p>
          <StandardButton
            label={"Discover More"}
            className={"home-header-button"}
            onClick={() => {
              document.getElementById("intro-section").scrollIntoView({ behavior: "smooth" });
            }}
          />
        </div>
      </header>

      {/* ================================== INTRO ================================== */}
      <section
        className={"home-intro-1 standard-width"}
        id={"intro-section"}
      >
        <h2 className={"section-head"}>Innovative UAVs<br />Infinite possibilities</h2>
      </section>

      {/* ================================== DRONE INTRO ================================== */}
      <section className={"home-drones-intro"}>
        <img
          className={"home-drones-intro-bg"}
          src={"/images/home/elevation-g2.jpg"}
        />
        <div className={"home-drones-intro-bg-color"} />

        <div className={"home-drones-intro-content standard-width"}>
          <h2 className={"section-head"}>Survey or surveillance?</h2>
        </div>

        <div className={"home-drones-intro-blocks standard-width"}>
          <div className={"home-drones-intro-block"}>
            <h3>ELEVATION-S</h3>
            <p>
              Optimised for surveying, mapping and linear infrastructure missions.
            </p>
            <Link to={PAGE_ROUTES.ELEVATION_S}>
              <StandardButton
                label={"Explore"}
                className={"home-drones-intro-button"}
              />
            </Link>
          </div>
          <div className={"home-drones-intro-block"}>
            <h3>ELEVATION-G</h3>
            <p>
              Equipped with a gimbal, ideally suited for surveillance and search & rescure missions.
            </p>
            <Link to={PAGE_ROUTES.ELEVATION_G}>
              <StandardButton
                label={"Explore"}
                className={"home-drones-intro-button"}
              />
            </Link>
          </div>
        </div>
      </section>

      {/* ================================== DRONE 1 ================================== */}
      <section className={"home-drone"}>
        <img
          className={"home-drone-bg"}
          src={"/images/home/elevation-s2.jpg"}
        />
        <div className={"home-drone-head"}>
          <p>Survey</p>
          <h2>ELEVATION-<span>S</span></h2>
        </div>
      </section>

      <section className={"home-drone-content standard-width"}>
        <p className={"info-text-block"}>
          Elevation-S is Avior Lab’s flagship fixed-wing Vertical-Take-Off-and-Landing (VTOL) Unmanned Aerial
          Vehicle (UAV) with 90-minutes of useful flight. It was developed in response to a requirement for
          an affordable and easily transportable but efficient, long endurance VTOL drone that can be adapted
          for a variety of survey missions with a simple change in payload. Its operation is as easy as planning
          a mission and pressing the “Start” button.
        </p>

        <Specs
          image={"/images/home/specs-3.jpg"}
          specItems={[
            {
              icon: "/images/home/icon-camera.png",
              text: "750G payload capacity, with flexible payload options",
            },
            {
              icon: "/images/home/icon-time.png",
              text: "90 minutes of useful flight, powered by a 210Wh battery",
            },
            {
              icon: "/images/home/icon-wing.png",
              text: "2.54m wingspan, with removable wings for easy transport",
            },
          ]}
        />

        <div className={"home-drone-industries"}>
          <IndustriesCarousel
            slides={[
              {
                head: "Mining",
                img: "/images/home/industry1.jpg",
                description: "Survey drones are becoming indispensable in the mining industry due to their ability to provide accurate, real-time data while significantly enhancing safety and efficiency. With Elevation-S one can quickly and safely map large and hazardous areas, capturing high-resolution images and 3D models that are crucial for planning and monitoring mining operations.",
              },
              {
                head: "Energy",
                img: "/images/home/industry2.jpg",
                description: "Elevation-S can quickly assess the condition of solar panels and coal conveyors, providing high-resolution images and thermal data that help identify potential issues such as corrosion or structural damage. By using Elevation-S, energy companies can reduce the need for manual inspections, which are not only time-consuming but also pose significant safety risks to personnel.",
              },
              {
                head: "Civil Structures",
                img: "/images/home/industry3.jpg",
                description: "Elevation-S can be used to survey linear infrastructure such as roads, pipelines, and railway lines. Its ability to provide a considerably longer useful flight compared to conventional multirotor drones enables it to survey over longer distances and in difficult to access locations.",
              },
              {
                head: "Agriculture",
                img: "/images/home/industry4.jpg",
                description: "Survey drones are highly beneficial for agriculture, offering farmers a powerful tool to enhance crop management and productivity. Elevation-S can quickly cover large fields, capturing high-resolution images and multispectral data that provide insights into crop health, soil conditions, and irrigation needs.",
              },
              {
                head: "Conservation",
                img: "/images/home/industry5.jpg",
                description: "Elevation-S, with its SilentFly™ technology, is ideal for wildlife monitoring and conservation efforts. The drone can capture data that can be further analysed by Avior Labs' Wildlife e-Census to help conservation managers track and study animal populations, monitor habitats, and identify threats to biodiversity.",
              },
            ]}
          />
        </div>

        <Link to={PAGE_ROUTES.ELEVATION_S}>
          <StandardButton
            label={"Learn more about Elevation-S"}
            className={"home-drone-button"}
          />
        </Link>
      </section>

      {/* ================================== DRONE 2 ================================== */}
      <section className={"home-drone"}>
        <img
          className={"home-drone-bg"}
          src={"/images/home/elevation-g3.jpg"}
        />
        <div className={"home-drone-head"}>
          <p>Surveillance</p>
          <h2>ELEVATION-<span>G</span></h2>
        </div>
      </section>

      <section className={"home-drone-content standard-width"}>
        <p className={"info-text-block"}>
          Based on the impressive performance of our Elevation-S survey drones and requests from numerous interested parties in a drone that
          can be used for security and other surveillance missions, we embarked on developing Elevation-G. Our SilentFly™ technology, which results
          in an extremely quiet noise footprint, was particularly attractive for this application. We therefore added one of the
          market-leading dual EO/IR camera equipped gimbals, a tracking computer and a datalink capable of streaming live video down to the
          operator. Elevation-G still retains the same ease of operation and 90-minute endurance of Elevation-S.
        </p>

        <Specs
          image={"/images/home/specs-4.jpg"}
          specItems={[
            {
              icon: "/images/home/icon-camera.png",
              text: "2-axis gimbal with RGB & thermal camera pair",
            },
            {
              icon: "/images/home/icon-time.png",
              text: "90 minutes of useful flight, powered by a 210Wh battery",
            },
            {
              icon: "/images/home/icon-silent.png",
              text: "SilentFly™ technology for low-noise operation",
            },
          ]}
          altStyle={true}
        />

        <div className={"home-drone-industries"}>
          <IndustriesCarousel
            slides={[
              {
                head: "Border Security",
                img: "/images/home/industry6-1.jpg",
                description: "Surveillance drones enhance border security by providing real-time aerial monitoring, allowing for quick detection and response to unauthorized crossings. Elevation-G can cover vast and difficult terrains more efficiently than ground patrols, reducing the need for extensive manpower and enabling quicker response times.",
              },
              {
                head: "Infrastructure Security",
                img: "/images/home/industry7.jpg",
                description: "Elevation-G can provide continuous 90-min monitoring of critical assets like pipelines and power lines, assisting in identifying potential threats or damages in real time. A combination of more than one Elevation-G operated on site can offer a prolonged uninterrupted surveillance.",
              },
              {
                head: "Search and Rescue",
                img: "/images/home/industry8.jpg",
                description: "Surveillance VTOL drones are crucial in search and rescue operations as they can quickly cover large areas, providing real-time visuals to locate missing persons more efficiently. The stable and clear footage provided by Elevation-G, which can also reach areas that would usually be difficult to access, can be invaluable in these high-pressure situations.",
              },
              {
                head: "Disaster Monitoring",
                img: "/images/home/industry9.jpg",
                description: "Drones play a vital role in disaster monitoring and recovery operations by providing rapid aerial assessments of affected areas, helping to identify the extent of damage and prioritize response efforts. The endurance and range of Elevation-G makes it ideal for these missions.",
              },
              {
                head: "Anti-poaching",
                img: "/images/home/industry10.jpg",
                description: "With wildlife poaching remaining an acute challenge in sub-Saharan Africa, the addition of Elevation-G to anti-poaching units can improve the protection efforts. Elevation-G can be used to track, locate and monitor poachers day or night.",
              },
            ]}
          />
        </div>
        
        <Link to={PAGE_ROUTES.ELEVATION_G}>
          <StandardButton
            label={"Learn more about Elevation G"}
            className={"home-drone-button"}
          />
        </Link>
      </section>

      {/* ================================== VIDEOS ================================== */}
      {/* <section className={"home-videos-section"}>
        <div className={"home-videos-bg"} />

        <div className={"standard-width"}>
          <h2 className={"section-head"}>Elevation in action</h2>
          <Tabs
            value={vidTab}
            onChange={(e, v) => setVidTab(v)}
            className={"home-videos-tabs"}
            variant={"fullWidth"}
            style={styles.videoTabs}
          >
            <Tab label={"In action"} />
            <Tab label={"Game counting"} />
          </Tabs>
          {
            (vidTab === 0) && (
              <div className={"home-video-outer"}>
                <video
                  className={"home-video"}
                  controls={true}
                >
                  <source
                    src={"/images/home/video-elevation.mp4"}
                    type={"video/mp4"}
                  />
                </video>
                <Link to={PAGE_ROUTES.ELEVATION_S}>
                  <StandardButton label={"Elevation Details"} />
                </Link>
              </div>
            )
          }
          {
            (vidTab === 1) && (
              <div className={"home-video-outer"}>
                <video
                  className={"home-video"}
                  controls={true}
                >
                  <source
                    src={"/images/home/video-game.mp4"}
                    type={"video/mp4"}
                  />
                </video>
                <Link to={PAGE_ROUTES.SOFTWARE}>
                  <StandardButton label={"Our Wildlife e-Census"} />
                </Link>
              </div>
            )
          }
        </div>
      </section> */}

      {/* ================================== CTA ================================== */}
      <section className={"home-cta-section"}>
        <div className={"standard-width"}>
          <div className={"home-videos-bg"} />
          <p className={"section-head-pre"}>Let's team up</p>
          <h2 className={"section-head"}>Arrange a demo</h2>
          <p className={"home-cta-text"}>
            We would love to show you how our drones can revolutionize your operations.
            Together with our UAVs & custom software, our team will help you achieve your goals faster, safer, and more cost-effectively.
            Get in touch with us today to schedule a demo.
          </p>
          <Link to={PAGE_ROUTES.CONTACT}>
            <StandardButton
              label={"Request a demo"}
              className={"home-cta-button"}
            />
          </Link>
          <img
            className={"home-cta-img standard-box"}
            src={"/images/home/cta.jpg"}
          />
        </div>
      </section>
    </main>
  );
};

export default Home;