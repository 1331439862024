import "./standardButton.css";

import React from "react";


const StandardButton = ({ label, className="", ...props }) => {
  return (
    <div
      className={`standard-button standard-box ${className}`}
      {...props}
    >
      <span>{label}</span>
      <div className={"corner-el-1"} />
      <div className={"corner-el-2"} />
      <div className={"corner-el-3"} />
      <div className={"corner-el-4"} />
    </div>
  );
};

export default StandardButton;